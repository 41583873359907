import React, { useContext }  from "react";
import SocialMediaCard from "../common/SocialMediaCard";
import { WebsiteContext } from "../../context/AppContext";

const SocialMediaSection = () => {
  const { isMobileView } = useContext(WebsiteContext);

  return (
    <div className={isMobileView ? "social_media_container_mobile" : "social_media_container"}
    >
      <SocialMediaCard
        title={"English With Reena"}
        count={22300}
        image={"youtube"}
        subcontent={"Subscribers"}
      />
      <SocialMediaCard
        title={"English_With_Reena"}
        count={98000}
        image={"instagram"}
        subcontent={"Followers"}
      />
    </div>
  );
};

export default SocialMediaSection;
